import fetch from 'isomorphic-fetch';
import queryString from 'query-string';
import { getContentAPIURL } from './../../../lib/content-api-utils';
import Logger from './../../../lib/logger';

const logger = Logger.get('ContentHub');
const apiURL = getContentAPIURL();


export default async function fetchNewsAPI(options = {}) {
	try {
		const filters = {};
		let filterString = '';

		if (options.filters && options.filters.length > 0) {
			options.filters.forEach((hubFilter) => {
				filters[hubFilter.type] = hubFilter.value;
			})
		}

		filterString = `?${queryString.stringify(filters)}`;

		const res = await fetch(`${apiURL}news/${filterString}`);
		const json = await res.json();

		return json;
	} catch (error) {
		logger.error(error);

		return {};
	}
}
