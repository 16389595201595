import React from 'react';
import PropTypes from 'prop-types';
import './EditorialText.scss';
import markdown from '../../lib/markdown-parser';

const EditorialText = ({data}) => {
	const textAlign = data.alignment == 'Center'
		? 'center'
		: (data.alignment == 'Right' ? 'right' : '');

	if (data.content) {
		return (
			<>
				{
					data.heading &&
					<h1 className="c-editorial-text__section__heading">
						{data.heading}
					</h1>
				}
				<div
					className={'c-editorial-text ' + textAlign}
					dangerouslySetInnerHTML={{__html: markdown(data.content)}}/>
			</>
		)
	}
	return (
		<div className={!!data.grayBackground ? 'editorial-text-container-gray' : 'editorial-text-container-black'}>
			{!!data.avatarImage ? (
				<div className="editorial-text-with-image">
					<div className="editorial-text-header">
						{!!data.avatarImage.file && data.avatarImage.file.url && (
							<img src={data.avatarImage.file.url} className="editorial-text-image"
								 alt={data.avatarImage.title}/>
						)}
						{!!data.avatarTitle && data.avatarName && (
							<p className="editorial-text-project">
								{data.avatarTitle}&nbsp;&nbsp; | &nbsp;&nbsp;{data.avatarName}
							</p>
						)}
					</div>
					{!!data.heading && <h3 className="editorial-text-title">{data.heading}</h3>}
					{!!data.introduction && <p className="editorial-text-introduction">{data.introduction}</p>}
				</div>
			) : (
				<div
					className={!!data.horizontalLine ? 'editorial-text-no-image-underline' : 'editorial-text-no-image'}>

					{!!data.heading && (
						<>
							<img className="editorial-node-before" src="/images/SailGP_Technologies_Node.png"/>
							<p className={data.alignment === 'Left' ? 'editorial-text-heading-left' : 'editorial-text-heading'}>{data.heading}</p>
							<img className="editorial-node-after" src="/images/SailGP_Technologies_Node.png"/>
						</>

					)}

					{!!data.introduction && <p className="editorial-text-introduction">{data.introduction}</p>}
				</div>
			)}
		</div>
	);
};

EditorialText.propTypes = {
	data: PropTypes.object.isRequired
};

export default EditorialText;
