import EditorialText from '../../components/EditorialText';
import { HeroImage as Image } from '../../components/HeroImage';
import ContactUs from '../../components/ContactUs/ContactUs';
import Pillars from '../../components/Pillars/Pillars';
import {CookieBannerContainer} from '../../components/CookieBanner/CookieBannerContainer';


import {
	ActionBanner,
	PromoContent,
	Gallery,
	EditorialProfile as Person,
	EditorialSummaryContainer as SummaryBox,
	MemoizedExternalVideo as ExternalVideo,
	EditorialCTA as EditorialCta,
	EditorialCTAContainer,
	EditorialHeader,
	MemoizedIFrameContent as IFrameContent,
	// EditorialImage as Image,
	MemoizedAdvertising as Advert,
	RaceHighlight,
	FeaturedContent,
	CarouselContainer,
	TeamListing,
	MemoizedHeroBanner as HeroBanner
} from '@sailgp/sailgp-shared-components';


import {ContentHubContainer} from '../../components/ContentHub/ContentHubContainer';
import {ContentHubMiniContainer} from '../../components/ContentHubMini/ContentHubMiniContainer';
import {TabContainer} from '../../components/Tabs/TabsContainer';
import {TeamContainer as Team} from '../../components/Team/teamContainer';
import {AccordionContainer as Accordion} from '../../components/Accordion/AccordionContainer';
import {RaceCalenderContainer as RaceEventListing} from './../../components/RaceCalendar/RaceCalenderContainer';


const allPageComponentsList = {
	ActionBanner,
	EditorialText,
	FeaturedContentContainer: FeaturedContent,
	Image,
	ContactUsMicrosite: ContactUs,
	TabContainer: Pillars,
	'Carousel': CarouselContainer,
	CookieBannerContainer,
	'ContentHub': ContentHubContainer,
	'MiniHub': ContentHubMiniContainer,
	'NanoHub': ContentHubMiniContainer,
	RaceHighlight,
	Advert,
	IFrameContent,
	RaceEventListing,
	PromoContent,
	EditorialHeader,
	SummaryBox,
	ExternalVideo,
	Gallery,
	Accordion,
	Team,
	Person,
	'EditorialCta' : EditorialCTAContainer,
	TeamListing
};


const editorialComponentsList = {
	HeroBanner,
	//todo following not yest available from shared-components
	// Table,
	// RaceSchedule,
	// Leaderboard,
	// RaceEvent,HeroBanner
	TeamListing,
	RaceHighlight,
	Advert,
	Image,
	IFrameContent,
	RaceEventListing,
	ActionBanner,
	PromoContent,
	EditorialHeader,
	EditorialText,
	SummaryBox,
	ExternalVideo,
	Gallery,
	Accordion,
	Team,
	FeaturedContentContainer: FeaturedContent,
	Person,
	'NanoHub': ContentHubMiniContainer,
	'EditorialCta' : EditorialCTAContainer,
	'MiniHub': ContentHubMiniContainer,
	TabContainer,
};

const tabComponentsList = {
	Accordion,
	EditorialText,
	Person,
	SummaryBox,
	EditorialCta,
	Team,
	Gallery,
	'MiniHub': ContentHubMiniContainer,
	'NanoHub': ContentHubMiniContainer
}

export default {
	allPageComponents: allPageComponentsList,
	editorial: editorialComponentsList,
	tabComponents: tabComponentsList
}
