import * as React from 'react';
import Button from '../Button'
import PropTypes from 'prop-types';
import {useLocalStorageState} from '../../hooks/useLocalStorageState';
import Modal from 'react-modal'
import './CookieBanner.scss'


export default function CookieBanner({acceptPrivacyPolicy, handlePrivacyModalToggle}) {
	const [, setPrivacyAcceptance] = useLocalStorageState('acceptedTechnologiesPrivacyPolicy', false);
	Modal.setAppElement('body');
	const handleLearnMoreClick = () => {
		window.open(
			'https://sailgp.com/general/cookie/',
			'_blank'
		);
	}

	const handleAcceptCookies = () => {
		setPrivacyAcceptance(true)
		handlePrivacyModalToggle()
	}

	return <Modal isOpen={acceptPrivacyPolicy} shouldCloseOnOverlayClick={false} className='cookie-banner-modal'>
		<div>
			<div className='cookie-banner-title'>
							THIS WEBSITE USES COOKIES
			</div>
			<div className='cookie-banner-description'>
							We use them to give you the best experience. If you continue using our website, we'll assume that you are happy to receive all cookies on this website.
			</div>
			<div className='cookie-banner-button-wrapper'>
				<Button className='cookie-banner-buttons' onClick={handleAcceptCookies}>
					<img src='/images/SailGP_Technologies_Node.png'/>
									Continue
				</Button>
				<Button
					className='cookie-banner-buttons'
					onClick={handleLearnMoreClick}
				>
					<img src='/images/SailGP_Technologies_Node.png'/>
								Learn More
				</Button>

			</div>
		</div>
	</Modal>

}

CookieBanner.propTypes = {
	acceptPrivacyPolicy: PropTypes.bool,
	handlePrivacyModalToggle: () => {}
}
