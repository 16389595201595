/* global process */

import Logger from 'js-logger';

const logLevel = process.env.BUILD_TARGET === 'client' ? localStorage.getItem('LOG_LEVEL') : 'OFF';

Logger.useDefaults({
	defaultLevel: Logger.OFF,
	formatter: function(messages, context) {
		const curDate = new Date();
		messages.unshift(`[${curDate.toLocaleDateString()} ${curDate.toLocaleTimeString()}] [${context.name}]`);
	}
});

if (logLevel === 'TRACE') {
	Logger.setLevel(Logger[logLevel]);
} else if (logLevel === 'DEBUG') {
	Logger.setLevel(Logger[logLevel]);
} else if (logLevel === 'INFO') {
	Logger.setLevel(Logger[logLevel]);
} else if (logLevel === 'TIME') {
	Logger.setLevel(Logger[logLevel]);
} else if (logLevel === 'WARN') {
	Logger.setLevel(Logger[logLevel]);
} else if (logLevel === 'ERROR') {
	Logger.setLevel(Logger[logLevel]);
} else if (logLevel === 'OFF') {
	Logger.setLevel(Logger[logLevel]);
} else {
	Logger.setLevel(Logger.OFF);
}

export default Logger;
