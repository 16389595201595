import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Button.scss';


const Button = props => {
	const { onClick, children, className, ...otherProps } = props;
	const classes = classnames('c-button', className);

	return (
		<button className={classes} onClick={onClick} {...otherProps}>
			{children}
		</button>
	);
};

Button.propTypes = {
	children: PropTypes.node.isRequired,
	onClick: PropTypes.func,
	className: PropTypes.string,
};

Button.defaultProps = {
	onClick: () => {},
	className: '',
};

export default Button;
