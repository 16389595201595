import React from 'react';
import './index.scss';

export default function Icon({onClick, isOpen}) {

	const handleToggle = () => {
		onClick();

	};

	return (
		<div id="nav-icon" className={isOpen ? 'open' : ''} onClick={handleToggle}>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>  

	)
}


