import React, {useState, useEffect} from 'react';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import CookieBanner from './CookieBanner';

export const CookieBannerContainer = () => {
	const [acceptedPrivacyPolicy,] = useLocalStorageState('acceptedTechnologiesPrivacyPolicy', false);
	const [openPrivacyModal, setOpenPrivacyModal] = useState(false);

	useEffect(() => {
		if (!acceptedPrivacyPolicy) {
			setOpenPrivacyModal(true)
		}
	}, [ acceptedPrivacyPolicy])

	return <CookieBanner
		acceptPrivacyPolicy={openPrivacyModal}
		handlePrivacyModalToggle={() => setOpenPrivacyModal(false)}
	/>



}
