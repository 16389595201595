/* global process */

/**
 * Get the content API url based on the running context, server or browser.
 *
 * @returns {String} - Content API url
 */
export function getContentAPIURL() {
	return process.env.BUILD_TARGET === 'client' ? process.env.CONTENT_API_URL_CLIENT : process.env.CONTENT_API_URL_SERVER;
}
