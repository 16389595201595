/* global process */

import { Component } from 'react';
import { renderRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import loadInitialData from './loadInitialData';

export const MICROSITE_TYPE = 'sgptechnologies'

export class App extends Component {
	constructor(props) {
		super(props);

		// Initial App state
		// data:    The page data coming from the content API
		// loading: Changing this will case a page re-render with a transition
		//          In our case we don't render the page again on loading from `PageContainer`.
		// error:   If this is not null will cause a page re-render with an error
		// loadInitialDataPromise: The initial data fetch promise
		this.state = {
			data: this.props.initialData,
			loading: false,
			error: null,
			loadInitialDataPromise: null
		};
	}


	async componentDidUpdate({ location: prevLocation }) {
		const loader = document.getElementsByClassName('loader')[0];
		if (this.props.location.pathname !== prevLocation.pathname) {
			const initialData = loadInitialData(this.props.location.pathname, this.props.routes);

			this.setState({
				data: null,
				loading: true,
				error: null,
				loadInitialDataPromise: initialData
			},
			);


			// Scroll to the top of the page when route changes
			// React Router doesn't implement this by default
			//
			// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
			// https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-react-router-scroll
			// https://github.com/ytase/react-router-scroll
			// window.scrollTo(0, 0);
		}

		if (this.state.loading) {
			loader.style.display = "";
			try {
				const data = await this.state.loadInitialDataPromise;
				this.setState({
					data,
					loading: false,
					error: null,
					loadInitialDataPromise: null
				},
				() => {
					window.scrollTo(0, 0);
				});
			} catch (error) {
				this.setState({
					data: null,
					loading: false,
					error: error,
					loadInitialDataPromise: null
				});
			}
		} else {
			loader.style.display = "none";
		}
	}

	// Add an Error Boundry
	// Error boundaries catch errors during rendering, in lifecycle methods, and in constructors of the whole tree below them.
	componentDidCatch() {
		// If we are in PRODUCTION mode and on the client side send all JS errors to a general 500 page
		if (process.env.BUILD_TARGET === 'client' && process.env.NODE_ENV === 'production') {
			window.location.replace('/general/500/');
		}
	}

	render() {
		return renderRoutes(this.props.routes, {
			data: this.state.data,
			loading: this.state.loading,
			error: this.state.error
		});
	}
}

App.propTypes = {
	initialData: PropTypes.object,
	cookies: PropTypes.object,
	location: PropTypes.object,
	routes: PropTypes.any
};

export default withRouter(App);
