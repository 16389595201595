import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { AngledDivider } from './../Dividers';

const ImgMask = props => {
	const { angledMask, angledMaskPosition, angledMaskFillColor, children } = props;

	if (angledMask) {
		return (
			<div className="c-img-mask">
				{(angledMaskPosition === 'top' || angledMaskPosition === 'top-bottom') && (
					<AngledDivider angleFillColor={angledMaskFillColor} angleDirection="right" anglePosition="bottom" className="c-img-mask-top-angle" />
				)}
				{children}
				{(angledMaskPosition === 'bottom' || angledMaskPosition === 'top-bottom') && (
					<AngledDivider angleFillColor={angledMaskFillColor} angleDirection="left" anglePosition="top" className="c-img-mask-bottom-angle" />
				)}
			</div>
		);
	}

	return <Fragment>{children}</Fragment>;
};

ImgMask.propTypes = {
	angledMask: PropTypes.bool,
	angledMaskPosition: PropTypes.string,
	angledMaskFillColor: PropTypes.string,
	children: PropTypes.any
};

export default ImgMask;
