import React, { useState, useEffect } from 'react';
import Navigation from './Tabs/Tabs';
import ThumbnailCarousel from '../ThumbnailCarousel/ThumbnailCarousel';
import FeaturedContent from '@sailgp/sailgp-shared-components';
import PropTypes from 'prop-types';
import ContactUs from '../ContactUs/ContactUs';
import nanoid from 'nanoid';

const Pillars = props => {
    let renderedTabComponent = [];
    if (props.data.tabs == undefined || props.data.tabs.length <= 0 || props.data.contentfulType != "TabContainer") {
        return null;
    }
    let tabData = props.data.tabs;

    const [tabContent, updateTabContent] = useState(tabData[0].content);

    function ArticleData(obj) {
        updateTabContent(obj.content);
    }

	useEffect(() => {
        updateTabContent(tabContent)
	}, [tabContent]);

    const tabComponentsList = {
        "FeaturedContent": FeaturedContent,
        "ContactUsMicrosite": ContactUs,
        "Carousel": ThumbnailCarousel
    };

    function fetchPropsData(obj) {
        let contentType = obj.contentfulType;
        switch (contentType) {
            case "FeaturedContent":
                let articleArray = [];
                articleArray.push(obj);
                let articleContent = {
                    data: {
                        items: articleArray
                    }
                };
                return articleContent;
            case "ContactUsMicrosite":
                let contactUsData = {
                    data: obj,
                    showBtn: "show"
                };
                return contactUsData;
            default:
                return obj;
        }
    }

    const tabComponents = tabContent.map((tabObj, index) => {
        if (tabComponentsList[tabObj.contentfulType]) {
            let getProps = fetchPropsData(tabObj);
            let SGPComponent = tabComponentsList[tabObj.contentfulType];
            return <SGPComponent key={nanoid()}  {...getProps} />
        }
    })

    renderedTabComponent = renderedTabComponent.concat(tabComponents);

    return (
        <>
            <Navigation tabsArray={tabData} tabClicked={ArticleData} />
            {renderedTabComponent}
        </>

    );
};

Pillars.propTypes = {
    tabs: PropTypes.array
};

Pillars.defaultProps = {
    tabs: undefined
};

export default Pillars;
