import marked from 'marked';
import sanitizeHtml from 'sanitize-html';

const renderer = new marked.Renderer();

const defaultOpts = {
	tables: true,
	breaks: true,
	smartypants: false,
};

const linkRenderer = renderer.link;
renderer.link = function (href, title, text) {
	const html = linkRenderer.call(renderer, href, title, text);
	return html.replace(/^<a /, '<a target="_blank" rel="noopener noreferrer" ');
};

export default function parseMarkdown (string, opts = {}) {
	marked.setOptions({
		...defaultOpts,
		...opts,
	});
	return string ? sanitizeHtml(marked(string, { renderer })) : null;
}
