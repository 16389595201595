import React from 'react';
import Slider from "react-slick";
import Card from './card/Card';
import './CarouselStyle.css'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

function VertBtnRightt(props) {
  const { onClick } = props;
  return (
    <FaAngleRight className='arrow right-arrow' onClick={onClick}/>
  );
}
function VertBtnLeftt(props) {
  const { onClick } = props;
  return (
      <FaAngleLeft  onClick={onClick} className="arrow left-arrow" />
  );
}

const ThumbnailCarousel = props => {
  if(props == undefined || props.contentfulType != "Carousel") {
    return null;
  }

  let {content:carouselData} = props;

  if(carouselData.length<=0) {
    return null;
  }

  var settings = {
    dots: true, 
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    mobileFirst:true,
    nextArrow: <VertBtnRightt></VertBtnRightt>,
    prevArrow: <VertBtnLeftt></VertBtnLeftt>,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

    return (
      <div className='carousel-wrap'>
        <div className='carousel-wrapper'>
          <Slider {...settings} className="slider">
          {carouselData.map((carouselObj, index) => (
            <Card
            key={index}
            heading={carouselObj.heading}
            url={carouselObj.image.file.url}
            cta={carouselObj.ctaText}
            ctaUrl={carouselObj.url}
            addModal={carouselObj.addModal}
          />
          ))}
          </Slider>
        </div>
      </div>
    )
}
export default ThumbnailCarousel;
