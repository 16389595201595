const validation = (values, validationForm) => {
	let errors = {};

	if (!values.firstName || values.firstName.trim().length === 0) {
		errors.firstName = validationForm.nameRequiredValidation;
	} else if (!/^[a-zA-Z ]+$/.test(values.firstName)) {
		errors.firstName = validationForm.nameErrorValidation;
	}

	if (!values.emailAddress) {
		errors.emailAddress = validationForm.emailRequiredValidation;
	} else if (!/\S+@\S+\.\S+/.test(values.emailAddress)) {
		errors.emailAddress = validationForm.emailErrorValidation;
	}
	if (!values.busPhone) {
		errors.busPhone = validationForm.phoneRequiredValidation;
	} else if (!/^[+0-9(][0-9().\- ]+$/.test(values.busPhone)) {
		errors.busPhone = validationForm.phoneErrorValidation;
	}

	if (!values.Location) {
		errors.Location = validationForm.locationRequiredValidation;
	}
	return errors;
};

export default validation;
