import React, {Component} from 'react';
import PropTypes from 'prop-types';
import pageComponentsList from '../../containers/ComponentsList';
import {Tabs} from '@sailgp/sailgp-shared-components';

export class TabContainer extends Component {
	render() {
		return <Tabs
			tabComponents={pageComponentsList.tabComponents}
			data={this.props.data}
		/>

	}
}

TabContainer.propTypes = {
	data: PropTypes.object,
};

TabContainer.defaultProps = {
	data: {},
};
