import React from 'react';
import { Link as LinkImport } from 'react-router-dom';
import './index.scss';
import nanoid from 'nanoid';
import PropTypes from 'prop-types';
import { MICROSITE_TYPE } from '../../App';
import Icon from './icon';

export default function HamburgerMenu({ navItems, subMenuOnly, setNavBarOpen, navBarOpen }) {
	const showSidebar = () => {
		setNavBarOpen(!navBarOpen);
		if (window.innerWidth >= 280 && window.innerWidth <= 480) {
			if (navBarOpen == false) {
				document.body.style.overflow = 'hidden';
			} else {
				document.body.style.overflow = 'auto';
			}
		}
	};

	const navigateHandler = () => {
		document.body.style.overflow = 'auto';
	};

	const createMenu = (navItem) => {
		let itemCounter = 0;
		const itemsLen = navItems.length;
		const element = (
			<ul className={navBarOpen ? 'menu active' : 'menu'}>
				{navItem.map((item) => {
					return (
						<React.Fragment key={nanoid()}>
							<li key={nanoid()} style={{ fontSize: '13px', paddingLeft: '11px' }}>
								<LinkImport className="menu-links" to={item.headingLink.replace(`/${MICROSITE_TYPE}`, '')} style={{ textDecoration: 'none'  }}>
									<img className="menu-node" src="/images/SailGP_Technologies_Node.png" />
									<span onClick={navigateHandler} className="span-link">
										{item.heading}
									</span>
								</LinkImport>
								{itemCounter != itemsLen ? <hr style={{ margin: '3px 0 15px 0', width: '100%', 'border-color':'gray' }} /> : <></>}
							</li>
						</React.Fragment>

					);
				})}
			</ul>
		);
		return element;
	};

	const primaryNavItems = navItems.filter((navItem) => !subMenuOnly ? true : navItem.isSubLink);

	return (
    <>
      <nav className={!subMenuOnly ? 'hamburger-navigation hiddenMenu' : 'hamburger-navigation'}>
      	<div>
      		<Icon isOpen={navBarOpen}  onClick={showSidebar} />
      		{createMenu(primaryNavItems)}
      	</div>
      	{navBarOpen && <div onClick={showSidebar}></div>}
      </nav>
    </>
	);
}

HamburgerMenu.propTypes = {
	navItems: PropTypes.array,
	subMenuOnly: PropTypes.bool,
	setNavBarOpen: PropTypes.func,
	navBarOpen: PropTypes.bool

};
