import React, { useState } from 'react';
import Location from './Location/Location';
import Contact from './ContactUsForm/ContactUsForm';
import './ContactUs.css';
import PropTypes from 'prop-types';
import EditorialText from '../EditorialText';

const ContactUs = props => {
	const contactUsForm = {
		formHeading: props.data.formHeading,
		formName: props.data.formName,
		formEmail: props.data.formEmail,
		formPhone: props.data.formPhone,
		formLocation: props.data.formLocation,
		formIndustry: props.data.formIndustry,
		formSubject: props.data.formSubject,
		formMessage: props.data.formMessage,
		formSubmitButton: props.data.formSubmitButton
	};
	const validationForm = {
		nameRequiredValidation: props.data.nameRequiredValidation,
		nameErrorValidation: props.data.nameErrorValidation,
		emailRequiredValidation: props.data.emailRequiredValidation,
		emailErrorValidation: props.data.emailErrorValidation,
		phoneRequiredValidation: props.data.phoneRequiredValidation,
		phoneErrorValidation: props.data.phoneErrorValidation,
		locationRequiredValidation: props.data.locationRequiredValidation
	};
	if (props.data == undefined) {
		return null;
	}
	let contactUsBtnText = props.data.button != undefined ? props.data.button : 'Contact Us';
	let btnDisplayInfo = props.showBtn == 'show' ? true : false;

	const [showContactUsBtn, updateShowContactUsBtn] = useState(contactUsBtnText);
	const [showContactForm, updateShowContactForm] = useState(!btnDisplayInfo);

	function contactUsBtnHandler() {
		updateShowContactForm(!showContactForm);
		updateShowContactUsBtn(showContactUsBtn == contactUsBtnText ? 'Close' : contactUsBtnText);
	}

	return (
		<div>
			<EditorialText className="contact-us-editorial-text" {...props} />
			<div className="contactus-container">
				<div className="show-contact-us">
					{btnDisplayInfo && (
						<button className="contact-btn" onClick={contactUsBtnHandler}>
							{showContactUsBtn}
						</button>
					)}
				</div>
				{showContactForm && (
					<div className="form-wrap">
						<div className="contact-us-info">
							<Location raceLocation={props.data} />
						</div>
						<div className="contact-us-info">
							<Contact showFormHeading={btnDisplayInfo} contactUsForm={contactUsForm} validationForm={validationForm} />
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

ContactUs.propTypes = {
	address: PropTypes.object
};

ContactUs.defaultProps = {
	address: undefined
};

export default ContactUs;
