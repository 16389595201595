import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import {CookiesProvider, withCookies, Cookies} from 'react-cookie';
import nanoid from 'nanoid';
import Logger from './../../lib/logger';
import StickyHeader from '../../components/StickyHeader';
import pageComponentsList from '../ComponentsList';
import '@styles-global';
import {CookieBannerContainer} from '../../components/CookieBanner/CookieBannerContainer';

import {
	EditorialHeader,
	MemoizedHeroBanner as HeroBanner,
	Footer
} from '@sailgp/sailgp-shared-components';

const logger = Logger.get('MicrositeContainer');

class MicrositeContainer extends Component {

	constructor(props) {
		super(props);

		this.state = {isLoading: true};

		this.getPageComponents = this.getPageComponents.bind(this);
		this.getGlobalPageComponents = this.getGlobalPageComponents.bind(this);
	}

	getPageComponents(components) {
		const pageComponents = [];

		// Add here all components that can be rendered.
		// Each key needs to match the Contentful type for that component.
		const componentList = pageComponentsList.allPageComponents;
		components.map((comp) => {
			if (componentList[comp.contentfulType]) {
				pageComponents.push({
					contentfulType: comp.contentfulType,
					contentfulId: comp.contentfulId,
					comp: componentList[comp.contentfulType],
				});
			} else {
				logger.error(`Trying to load component '${comp.contentfulType}' but it doesn't exist in components list!`);
			}

			return null;
		});

		return pageComponents;
	}

	getGlobalPageComponents(pageData) {
		const componentsListTop = [];
		const componentsListBottom = [];
		let finalComponents = {};
		const siteSettings = pageData.siteSettings;
		const content = pageData.content;
		const hostUrl = pageData.hostUrl;
		componentsListTop.push(
			{
				component: StickyHeader,
				data: pageData
			},
		)
		componentsListTop.push({
			component: HeroBanner,
			data: {
				heading: content.heading,
				media: content.heroImage,
				showHero: content.showHero,
				isHomeHero: content.isHomeHero,
				darkBottomAngle: content.components.length === 0,
				subtitle: content.subTitle,
				theme: content.theme,
				title: content.heroIntro1,
				title2: content.heroIntro2,
				videoStreamUrl: content.videoStreamUrl,
				videoStreamText: content.videoStreamText,
				hideBroadcasterCta: content.hideBroadcasterCta,
				broadcasterConfigUrl: siteSettings.app_broadcasterConfigUrl,
				watchLiveLabel: content.watchLiveLabel,
			}
		});

		componentsListTop.push({
			component: EditorialHeader,
			data: {
				pageInformation: content.pageInformation,
				relatedEntities: content.relatedEntities,
				date: content.publishDate,
				featureLabel: content.pageType,
				socialLinks: {
					facebook: siteSettings.facebookUrl,
					twitter: siteSettings.twitterHandle,
				},
				byLabel: content.byLabel,
				author: content.authorName,
				pageUrl: `${siteSettings.baseUrl}${content.url}`,
				heading: content.heading,
				hostnamePageUrl: `${hostUrl}${content.url}`
			}
		});

		if (siteSettings) {
			componentsListBottom.push(
				{
					component: Footer,
					data: {
						copyrightNotice: siteSettings.copyrightNotice,
						footerNavigationLists: siteSettings.footerNavigationLists,
						footerUtilityLinks: siteSettings.footerUtilityLinks,
						socialLabel: siteSettings.socialLabel,
						logo: siteSettings.logo,
						socialLinks: {
							facebookUrl: siteSettings.facebookUrl,
							twitterHandle: siteSettings.twitterHandle,
							instagramUrl: siteSettings.instagramUrl,
							youTubeUrl: siteSettings.youTubeUrl,
							weiboUrl: siteSettings.weiboUrl,
							weChatUrl: siteSettings.weChatUrl
						},
						downloadAppLabel: siteSettings.downloadAppLabel,
						appStoreLink: siteSettings.appStoreLink,
						googlePlayLink: siteSettings.googlePlayLink,
						useDazzle: false,
						partners: siteSettings.partners
					}
				}
			)
			componentsListTop.push({
				component: CookieBannerContainer,
				data: {
					cookieMessage: siteSettings.cookieMessage
				}
			})
		}

		const topComponents = componentsListTop.map(({component, data}) => {
			const SGPComponent = component;
			return <SGPComponent
				key={nanoid()}
				data={data}
			/>
		});

		const bottomComponents = componentsListBottom.map(({component, data}) => {
			const SGPComponent = component;
			return <SGPComponent
				key={nanoid()}
				data={data}
			/>
		});

		finalComponents.topComponents = topComponents;
		finalComponents.bottomComponents = bottomComponents;

		return finalComponents;
	}

	componentDidMount() {
		this.setState({isLoading: false});
	}

	render() {

		let finalPageComponents = [];
		const {data, cookies} = this.props;
		if (data) {
			const {components, otherComponents} = data.content;
			const pageComponents = this.getPageComponents(components);
			const pageOtherComponents = this.getPageComponents(otherComponents);
			const globalPageComponents = this.getGlobalPageComponents(data);

			let componentsElements = pageComponents.map(({contentfulId, comp}) => {
				const SGPComponent = comp;

				return <SGPComponent
					key={nanoid()}
					data={_.find(components, ['contentfulId', contentfulId])}
					cookieProvider={cookies}
				/>
			});

			const otherComponentsElements = pageOtherComponents.map(({contentfulId, comp}) => {
				const SGPComponent = comp;

				return <SGPComponent
					key={nanoid()}
					data={_.find(otherComponents, ['contentfulId', contentfulId])}
					cookieProvider={cookies}
				/>
			});

			finalPageComponents = finalPageComponents.concat(globalPageComponents.topComponents);
			finalPageComponents = finalPageComponents.concat(componentsElements);
			finalPageComponents = finalPageComponents.concat(otherComponentsElements);
			finalPageComponents = finalPageComponents.concat(globalPageComponents.bottomComponents);
		}
		return (
			<CookiesProvider>
				{!this.state.isLoading && finalPageComponents}
			</CookiesProvider>
		);
	}

}

export default withRouter(withCookies(MicrositeContainer));
