import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './DazzleDivider.scss';

const DazzleDivider = ({
	fillColor = '#000',
	backgroundColor,
	anglePosition = 'bottom',
	inverted = false,
	className
}) => {
	const classes = classnames('c-divider--dazzle', className);

	const dazzleStandardBackgroundColors = {
		'deep-ocean-blue': '#061A1B'
	}

	const dazzleStandardColors = {
		'supercharged-red': '#953022',
		'new-ocean-blue': '#53A1A3',
		'black': '#000000',
		'white': '#ffffff',
		'light-grey': '#E0E2E2',
		'deep-ocean-blue': '#061A1B'
	};

	if (Object.keys(dazzleStandardColors).find(el => el === fillColor)) {
		fillColor = dazzleStandardColors[fillColor];
	}

	if (Object.keys(dazzleStandardBackgroundColors).find(el => el === backgroundColor)) {
		backgroundColor = dazzleStandardColors[backgroundColor];
	}

	const svgStyles = {
		...(fillColor && { fill: `${fillColor}` })
	};

	const containerStyles = {
		...(backgroundColor && { backgroundColor: `${backgroundColor}` })
	};

	let jsx;

	if(anglePosition === 'bottom') {
		if (inverted === false) {
			jsx = <Fragment>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 197" width="100%" height="100%" preserveAspectRatio="none" style={svgStyles} className="c-divider--dazzle-md">
					<path d="M48.9650896 121.4255L300.718822 99.449543 447.9623 186.335651 452.478409 197H177.039108L48.9650896 121.4255zM441.193729 87.18728l40.172082-3.50668L529.391049 197H487.73312L441.193729 87.18728zm72.400899-6.319982l227.466608-19.855897 29.771703 17.551772-2.393358-19.941668L898.50333 47.268047 1152.48332 197H863.276231l-83.260863-49.085979L785.907157 197H682.525299l-159.583568-94.080936-9.347103-22.051766zm542.377122-47.344903L1440 0v154.01497L1260.20985 47.897673l13.76034 113.855668L1333.67863 197h-58.23062l-4.41797-36.750109-215.05829-126.727496zM42.4864865 197H0v-25.051793L42.4864865 197z"/>
				</svg>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375 71" width="100%" height="100%" preserveAspectRatio="none" style={svgStyles} className="c-divider--dazzle-sm">
					<path d="M26.107308 30.594291l134.230577-11.770637 78.575341 46.475296L241.321818 71H94.420857L26.107308 30.594291zm209.177536-18.342713l21.423428-1.878613L282.341893 71h-22.217562l-24.839487-58.748422zm38.610777-3.385767L375 0v71h-10.986507l-85.111236-50.294673-5.006636-11.839516zM25.10991 71H0V56.15929L25.10991 71z"/>
				</svg>
			</Fragment>
		} else {
			jsx = <Fragment>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 197" width="100%" height="100%" preserveAspectRatio="none" style={svgStyles} className="c-divider--dazzle-md">
					<g>
						<polygon points="300.8,99.5 448,186.3 452.5,197 487.7,197 441.2,87.2"/>
						<polygon points="0,125.8 0,171.9 42.5,197 177,197 49.1,121.5"/>
						<polygon points="770.8,78.6 768.4,58.7 741.1,61"/>
						<polygon points="1274,161.8 1333.7,197 1440,197 1440,154 1260.2,47.9"/>
						<polygon points="1056,33.5 898.5,47.3 1152.5,197 1275.4,197 1271,160.2"/>
						<polygon points="513.6,80.9 481.4,83.7 529.4,197 682.5,197 522.9,102.9"/>
						<polygon points="785.9,197 863.3,197 780,147.9"/>
					</g>
				</svg>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375 71" width="100%" height="100%" preserveAspectRatio="none" style={svgStyles} className="c-divider--dazzle-sm">
					<g>
						<polygon points="0,32.9 0,56.2 25.1,71 94.4,71 26.1,30.6"/>
						<polygon points="273.9,8.9 256.7,10.4 282.3,71 364,71 278.9,20.7"/>
						<polygon points="160.3,18.8 238.9,65.3 241.3,71 260.1,71 235.3,12.3"/>
					</g>
				</svg>
			</Fragment>
		}
	} else {
		jsx = <Fragment>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 184" width="100%" height="100%" preserveAspectRatio="none" style={svgStyles} className="c-divider--dazzle-md">
				<path d="M1317.9155 82.163725l-57.70565-34.145355 4.67581 38.786073-103.52775 9.059863L999.083788 0H1440v71.479934l-122.0845 10.683791zM1003.84716 109.6483l-409.347647 35.822605-71.557782-42.292497L479.317329 0h158.255022l133.260588 78.761143L761.403946 0h56.921853l185.521361 109.6483zm-493.341993 43.173071l-40.171901 3.515502-35.814359-84.719255L313.362198 0h132.539424l64.603545 152.821371zm-104.526848 9.147296L154.224592 184 0 92.765126V0h132.184438l273.793881 161.968667z"/>
			</svg>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375 64" width="100%" height="100%" preserveAspectRatio="none" style={svgStyles} className="c-divider--dazzle-sm">
				<path d="M317.013101 43.4405l-38.110844-22.5106L270.047521 0H375l-.147025 38.37538-57.839874 5.06512zm-44.756342 3.91938l-21.424017 1.87614-19.089325-45.12817L224.789808 0h27.433733l20.033218 47.35988zm-55.771362 4.88399L82.239298 64 0 15.37957V0h128.117224l88.368173 52.24387z"/>
			</svg>
		</Fragment>
	}

	return (
		<div className={classes} style={containerStyles}>
			{ jsx }
		</div>
	);
};

DazzleDivider.propTypes = {
	fillColor: PropTypes.string,
	backgroundColor: PropTypes.string,
	anglePosition: PropTypes.string,
	inverted: PropTypes.bool,
	className: PropTypes.string
};

export { DazzleDivider }; // Enzyme support

export default memo(DazzleDivider);
