import {useEffect, useState} from 'react';

function useLocalStorageState(key, defaultVal) {
	const [state, setState] = useState(() => {
		let value;
		try {
			const rawItem = localStorage.getItem(key);
			value = rawItem
				? JSON.parse(rawItem)
				: defaultVal;
		} catch (error) {
			value = defaultVal;
		}
		return value;
	});

	useEffect(() => {
		window.localStorage.setItem(key, JSON.stringify(state));
	}, [state, key]);

	return [state, setState]
}

export { useLocalStorageState };
