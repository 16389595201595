import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import 'intersection-observer';
import smoothscroll from 'smoothscroll-polyfill';
import App from './App';
import Logger from './lib/logger';

const logger = Logger.get('ServiceWorkerUnregister');

// Firefox and Safari are patchy and have some bugs.
// If they ever get fixed (FF has on nightly) remove this line.
window.__forceSmoothScrollPolyfill__ = true;
smoothscroll.polyfill();

// This script is to unregister service workers that may 
// have been installed on the previous holding site

if (window.navigator && navigator.serviceWorker) {
	// check if service workers are supported
	navigator.serviceWorker.getRegistrations().then(registrations => {
		// get all registered serviceworkers
		for (const registration of registrations) {
			// unregister each one
			registration.unregister();
		}
		return true;
	}).catch(error => logger.error(error));
}

export const ClientApp = props => (
	<BrowserRouter {...props}>
		<App initialData={props.data} routes={props.routes} />
	</BrowserRouter>
);

ClientApp.propTypes = {
	data: PropTypes.object,
	routes: PropTypes.array
}
