import React from 'react';
import "./Location.css";
import { FaMapMarkerAlt } from 'react-icons/fa';

const Location = (props) => {
    if (props.raceLocation.contentfulType != "ContactUsMicrosite") {
        return null;
    }

    return (
        <div>
            {props.raceLocation.locationHeading != undefined && <p className="address-heading">{props.raceLocation.locationHeading}</p>}
            <div>
                {props.raceLocation.addressList != undefined &&
                    props.raceLocation.addressList.map((addr, index) => (
                        addr.contentfulType == "EditorialText" && 
                        <pre className='each-addr' key={index}><FaMapMarkerAlt className='location-icon'/>
                            <div className='address'>{addr.content != undefined && addr.content}</div>
                        </pre>
                    ))}
            </div>
        </div>
    )
}

export default Location;