import React from 'react';
import { render, hydrate } from 'react-dom';
import { ClientApp } from './app/ClientApp';
import routes from './app/routes';

const data = window.SAILGP_DATA || {};
const root = document.getElementById('root');

const renderMethod = module.hot ? render : hydrate;
renderMethod(<ClientApp data={data} routes={routes} />, root);

if (module.hot) {
	module.hot.accept();
}
