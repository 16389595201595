import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import PageContext from '../../context/PageContext';
import {Team} from '@sailgp/sailgp-shared-components';
import {fetchPageContent} from '../../utils/fetchPageContent';
import pageComponentsList from './../../containers/ComponentsList';


export class TeamContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			pageContent: undefined,
			drawerContentUrl: undefined,

			drawerOpen: false,
			drawerContentLoading: false,
			drawerContentPage: undefined,
		};

		this.handleCardClick = this.handleCardClick.bind(this);
		this.handleEditorialClose = this.handleEditorialClose.bind(this);
		this.updatePageContent = this.updatePageContent.bind(this);
		this.clearPageContent = this.clearPageContent.bind(this);
		this.handleEditorialLoadingFinished = this.handleEditorialLoadingFinished.bind(this);
	}

	async updatePageContent() {
		try {
			this.setState({
				pageContent: await fetchPageContent(this.state.drawerContentPage.url)
			});

		} finally {
			if (this.handleEditorialLoadingFinished) {
				this.handleEditorialLoadingFinished();
			}
		}
	}

	clearPageContent() {
		this.setState({
			pageContent: undefined
		});
	}


	handleCardClick(page, prevLocation) {
		this.urlPrevLocation = prevLocation;
		this.setState({
			drawerOpen: true,
			drawerContentPage: page,
			drawerContentLoading: true
		});
	}

	handleEditorialClose() {
		window.history.pushState(null, null, this.urlPrevLocation);
		this.setState({ drawerOpen: false });
	}

	handleEditorialLoadingFinished() {
		this.setState({
			drawerContentLoading: false
		});
	}

	render() {
		return (
			<PageContext.Consumer>
				{({activeLanguage}) => (
					<Team
						pageComponentsList={pageComponentsList}
						containerState={this.state}
						activeLanguage={{activeLanguage}}
						data={this.props.data}
						isParentTab={this.props.isParentTab}
						handleCardClick={this.handleCardClick}
						handleEditorialClose={this.handleEditorialClose}
						updatePageContent={this.updatePageContent}
						clearPageContent={this.clearPageContent}
						handleEditorialLoadingFinished={this.handleEditorialLoadingFinished}
					/>
				)}
			</PageContext.Consumer>
		);
	}
};

TeamContainer.propTypes = {
	data: PropTypes.object,
	isParentTab: PropTypes.bool,

};

TeamContainer.defaultProps = {};
