import React from 'react';
import { Link as LinkImport } from 'react-router-dom';
import nanoid from 'nanoid';
import PropTypes from 'prop-types';

import HamburgerMenu from '../HamburgerMenu';
import './index.scss';

export default function Navigation({hamburgerNav, navItems, navBarOpen, setNavBarOpen}) {

	if (navItems == undefined || navItems.length <= 0) {
		return <></>;
	}

	return (
		<div className="desktop-navigation">
			<ul className="desktop-list">
				{navItems.map((navItem, index) => {
					return (
						<li className="desktop-list-item" key={nanoid()}>
							{navItem.links.length === 0 && (
								<LinkImport to={navItem.headingLink} style={{ textDecoration: 'none' }}>
									<span className="span-item">{navItem.heading}</span>
								</LinkImport>
							)}
						</li>
					);
				})}
				<li className="desktop-list-item">
					<HamburgerMenu navBarOpen={navBarOpen} setNavBarOpen={(e) => setNavBarOpen(e)} navItems={hamburgerNav} subMenuOnly={true}/> 
				</li>
			</ul>
		</div>
	);
}
Navigation.propTypes = {
	hamburgerNav: PropTypes.array,
	navItems: PropTypes.array,
	navBarOpen: PropTypes.bool,
	setNavBarOpen: PropTypes.func

};