import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './AngledDivider.scss';

const AngledDivider = ({
	dividerFillColor = '#000',
	angleFillColor = '#000',
	angleDirection = 'right',
	anglePosition = 'bottom',
	baseBar = false,
	baseBarHeight = undefined,
	applyShadow = false,
	polygonPoints = `0,0 100,0 0,100`,
	className
}) => {
	const classes = classnames('c-divider--angled', `c-divider--fix-${anglePosition}`, className);

	const baseBarStyles = {
		...(dividerFillColor && { backgroundColor: `${dividerFillColor}` }),
		...(baseBarHeight && { height: `${baseBarHeight}` })
	};

	const svgStyles = {
		...(angleFillColor && { fill: `${angleFillColor}` })
	};


	if (angleDirection === 'left') {
		polygonPoints = '0,0 100,0 100,100';
	}

	if (anglePosition === 'top') {
		polygonPoints = `0,0 100,100 0,100`;

		if (angleDirection === 'left') {
			polygonPoints = `101,0 101,101 0,100`; // Using 100 + 1 to fix a white line between the base bar and the SVG
		}
	}

	return (
		<div className={classes}>
			{ baseBar && anglePosition === 'bottom' ? <div className="c-divider--angled__base-bar" style={baseBarStyles}/> : null }
			<div className="c-divider--angled-box">
				<svg viewBox="0 0 100 100" preserveAspectRatio="none" style={svgStyles}>
					<defs>
            			<filter id="feDropShadow2">
                			<feDropShadow stdDeviation="1.00, 4.00" dx="1.00" dy="4.00" floodColor="black" floodOpacity="0.60"/>
            			</filter>
        			</defs>
					<polygon filter= {applyShadow? "url(#feDropShadow2)" : ""} points={polygonPoints} />
				</svg>
			</div>
			{ baseBar && anglePosition === 'top' ? <div className="c-divider--angled__base-bar" style={baseBarStyles}/> : null }
		</div>
	);
};

AngledDivider.propTypes = {
	dividerFillColor: PropTypes.string,
	angleFillColor: PropTypes.string,
	angleDirection: PropTypes.string,
	anglePosition: PropTypes.string,
	baseBar: PropTypes.bool,
	baseBarHeight: PropTypes.string,
	className: PropTypes.string,
	applyShadow: PropTypes.bool,
	polygonPoints: PropTypes.string
};

export { AngledDivider }; // Enzyme support

export default memo(AngledDivider);
