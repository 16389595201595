import React from 'react';
import PropTypes from 'prop-types';
import {MemoizedAccordion as Accordion} from '@sailgp/sailgp-shared-components';
import TechnologiesNode from '../../../../public/images/SailGP_Technologies_Node.png'

export const AccordionContainer = ({ data, cookieProvider }) => <Accordion data={data} cookieProvider={cookieProvider} icon={TechnologiesNode}/>;

AccordionContainer.propTypes = {
	data: PropTypes.object,
	cookieProvider: PropTypes.object
}
