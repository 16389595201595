import _ from 'lodash';

/**
 * Global responsive images breakpoints
 */
const responsiveImagesBreakpoints = [768, 1263, 1642, 1920];

/**
 * Get a media URL with all the API params based on the options needed
 * https://www.contentful.com/developers/docs/references/images-api/
 *
 * @param {*} url - Image URL
 * @param {*} options - Options object to manipulate the image
 * @returns {String} Image URL with all API params appended
 */
function getMediaResource(url, options) {
	const defaultOpts = {
		w: 1920,
		h: undefined,
		focus: undefined,
		fit: 'fill',
		format: 'jpg',
		progressive: true
	};

	const opts = { ...defaultOpts, ...options };

	let resourceURL = `${url}?fm=${opts.format}`;

	if (opts.format === 'jpg') {
		resourceURL += '&fl=progressive&q=85';
	}

	if (opts.w) {
		resourceURL += `&w=${opts.w}`;
	}

	if (opts.h) {
		resourceURL += `&h=${opts.h}`;
	}

	if (opts.focus) {
		resourceURL += `&f=${opts.focus}`;
	}

	if (opts.w && opts.h) {
		resourceURL += `&fit=${opts.fit}`;
	}

	return resourceURL;
}

/**
 * Return the HTML markup for certain Picture & IMG attributes
 *
 * @param {*} url - Image URL that needs to be parsed
 * @param {*} attr - The attribute for wich the request is done for {sizes | srcset}
 * @param {*} options - Options object passed to manipulate the image
 * @returns {String} HTML markup
 * TODO: This function will cap the width to the max value from `responsiveImagesBreakpoints`
 * but if both w and h are passed the height is not recalculated based on the aspect ratio,
 * and fit=fill will be automatically be applied, this will result in bad renditions of the image
 * becuse the fill will crop when heigth is larger than 1920. We should recalculate the height or remove the fill.
 */
function getResponsiveMarkup(url, attr, options) {
	const defaultOpts = {
		w: 1920,
		fit: 'fill',
		format: 'jpg',
		progressive: true
	};

	const opts = Object.assign({}, defaultOpts, options);

	let markup = '';

	if (attr === 'sizes') {
		let maxSize = _.max(responsiveImagesBreakpoints);

		for (let index = 0; index < responsiveImagesBreakpoints.length; index++) {
			maxSize = responsiveImagesBreakpoints[index]

			if (opts.w <= maxSize) {
				break;
			}
		}

		markup = `(max-width: ${maxSize}px) 100vw, ${maxSize}px`;
	} else if (attr === 'srcset') {
		for (let index = 0; index < responsiveImagesBreakpoints.length; index++) {
			const size = responsiveImagesBreakpoints[index];

			markup += `${getMediaResource(url, {...opts, w: size})} ${size}w${index < responsiveImagesBreakpoints.length - 1 ? ',': ''}`;

			if (opts.w <= size) {
				break;
			}
		}
	}

	return markup;
}

export { responsiveImagesBreakpoints, getMediaResource, getResponsiveMarkup };
