import React from 'react';
import './ContactUsForm.css';
import { useState } from 'react';
import validation from './Validation';
import { FaUserCircle, FaGlobeAsia } from 'react-icons/fa';
import { HiPhone, HiOutlineMail } from 'react-icons/hi';

function Contact(props) {
	const [values, setValues] = useState({
		firstName: '',
		emailAddress: '',
		busPhone: '',
		Location: '',
		industry1: '',
		Subject: '',
		Message: ''
	});
	const [infocusField, setinfocusField] = useState({
		firstName: false,
		emailAddress: false,
		busPhone: false,
		Location: false,
		industry1: false,
		Subject: false,
		Message: false
	});
	const handleBlur = event => {
		handleChange(event);
		setinfocusField({
			...infocusField,
			[event.target.name]: true
		});
	};
	const [errors, setErrors] = useState({});
	const isInvalid = () => {
		if (Object.keys(errors).length === 0) return true;
		const isInputsInvalid = errors.firstName || errors.busPhone || errors.emailAddress || errors.Location ? true : false;
		return isInputsInvalid;
	};
	const [isInputsInvalid, setisInputsInvalid] = useState(isInvalid());

	const handleChange = event => {
		setValues({
			...values,
			[event.target.name]: event.target.value
		});
		const inputErrors = validation(values, props.validationForm);
		setErrors(inputErrors);

		setisInputsInvalid(inputErrors.firstName || inputErrors.emailAddress || inputErrors.busPhone || inputErrors.Location ? true : false);
	};

	return (
		<>
			<div className="contact-form">
				{!props.showFormHeading && <h3 className="form-heading">{props.contactUsForm.formHeading}</h3>}
				<div className="form-style">
					<form
						method="POST"
						action="https://s6148858.t.eloqua.com/e/f2"
						name="22_Q1_Global_EN_Core_Builders_Contact_us"
						id="form30"
						className="elq-form"
					>
						<input value="22_Q1_Global_EN_Core_Builders_Contact_us" type="hidden" name="elqFormName" />
						<input value="6148858" type="hidden" name="elqSiteId" />
						<input name="elqCampaignId" type="hidden" />
						<div className="layout container-fluid">
							<div className="row">
								<div className="grid-layout-col">
									<div className="layout-col col-sm-12 col-xs-12 entry-field form-entry">
										<div id="formElement0" className="elq-field-style form-element-layout row ">
											<div className="col-sm-12 col-xs-12 label-field">
												<label className="elq-label ">
													{' '}
													<FaUserCircle />
													{props.contactUsForm.formName}
												</label>
											</div>
											<div className="col-sm-12 col-xs-12 label-style">
												<div className="row">
													<div className="col-xs-12">
														<div className="field-control-wrapper name_field">
															<input
																type="text"
																className="elq-item-input input-field-style"
																name="firstName"
																id="fe278"
																autoComplete="off"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.firstName}
															/>
															{errors.firstName && infocusField.firstName && <p className="validation-msg">{errors.firstName}</p>}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="grid-layout-col">
									<div className="layout-col col-sm-12 col-xs-12 entry-field form-entry">
										<div id="formElement1" className="elq-field-style form-element-layout row">
											<div className="col-sm-12 col-xs-12 label-style">
												<label className="elq-label ">
													{' '}
													<HiOutlineMail />
													{props.contactUsForm.formEmail}
												</label>
											</div>
											<div className="col-sm-12 col-xs-12">
												<div className="row">
													<div className="col-xs-12">
														<div className="field-control-wrapper">
															<input
																type="email"
																className="elq-item-input input-field-style"
																name="emailAddress"
																value={values.emailAddress}
																autoComplete="off"
																onChange={handleChange}
																onBlur={handleBlur}
																id="fe279"
															/>
															{errors.emailAddress && infocusField.emailAddress && (
																<p className="validation-msg">{errors.emailAddress}</p>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="grid-layout-col">
									<div className="layout-col col-sm-12 col-xs-12 entry-field form-entry">
										<div id="formElement2" className="elq-field-style form-element-layout row">
											<div className="col-sm-12 col-xs-12 label-style">
												<label className="elq-label ">
													{' '}
													<HiPhone />
													{props.contactUsForm.formPhone}
												</label>
											</div>
											<div className="col-sm-12 col-xs-12">
												<div className="row">
													<div className="col-xs-12">
														<div className="field-control-wrapper">
															<input
																type="text"
																className="elq-item-input input-field-style"
																name="busPhone"
																value={values.busPhone}
																autoComplete="off"
																onChange={handleChange}
																onBlur={handleBlur}
																id="fe280"
															/>
															{errors.busPhone && infocusField.busPhone && <p className="validation-msg">{errors.busPhone}</p>}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="grid-layout-col">
									<div className="layout-col col-sm-12 col-xs-12 entry-field form-entry">
										<div id="formElement3" className="elq-field-style form-element-layout row">
											<div className="col-sm-12 col-xs-12 label-style">
												<label className="elq-label ">
													<FaGlobeAsia />
													{props.contactUsForm.formLocation}
												</label>
											</div>
											<div className="col-sm-12 col-xs-12">
												<div className="row">
													<div className="col-xs-12">
														<div className="field-control-wrapper">
															<input
																type="text"
																className="elq-item-input input-field-style"
																name="Location"
																value={values.Location}
																autoComplete="off"
																onChange={handleChange}
																onBlur={handleBlur}
																id="fe281"
															/>
															{errors.Location && infocusField.Location && <p className="validation-msg">{errors.Location}</p>}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="grid-layout-col">
									<div className="layout-col col-sm-12 col-xs-12 entry-field form-entry">
										<div id="formElement4" className="elq-field-style form-element-layout row">
											<div className="col-sm-12 col-xs-12 label-style">
												<label className="elq-label ">{props.contactUsForm.formIndustry}</label>
											</div>
											<div className="col-sm-12 col-xs-12">
												<div className="row">
													<div className="col-xs-12">
														<div className="field-control-wrapper">
															<select
																className="elq-item-select input-field-style"
																id="fe282"
																name="industry1"
																value={values.industry1}
																autoComplete="off"
																onChange={handleChange}
																onBlur={handleBlur}
																placeholder="Select industry"
																data-value=""
															>
																<option value="">Please Select...</option>
																<option value="Aerospace &amp; Defense">Aerospace &amp; Defense</option>
																<option value="Agriculture">Agriculture</option>
																<option value="Automotive &amp; Transport">Automotive &amp; Transport</option>
																<option value="Banking">Banking</option>
																<option value="Beverages">Beverages</option>
																<option value="Business Services">Business Services</option>
																<option value="Charitable Organizations">Charitable Organizations</option>
																<option value="Chemicals">Chemicals</option>
																<option value="Computer Hardware">Computer Hardware</option>
																<option value="Computer Services">Computer Services</option>
																<option value="Computer Software">Computer Software</option>
																<option value="Construction">Construction</option>
																<option value="Consumer Products Manufacturers">Consumer Products Manufacturers</option>
																<option value="Consumer Services">Consumer Services</option>
																<option value="Cultural Institutions">Cultural Institutions</option>
																<option value="Education">Education</option>
																<option value="Electronics">Electronics</option>
																<option value="Energy &amp; Utilities">Energy &amp; Utilities</option>
																<option value="Entertainment">Entertainment</option>
																<option value="Environmental Services &amp; Equipment">
																	Environmental Services &amp; Equipment
																</option>
																<option value="Financial Services">Financial Services</option>
																<option value="Food">Food</option>
																<option value="Foundations">Foundations</option>
																<option value="Government">Government</option>
																<option value="Health Care">Health Care</option>
																<option value="Industrial Manufacturing">Industrial Manufacturing</option>
																<option value="Insurance">Insurance</option>
																<option value="Leisure">Leisure</option>
																<option value="Media">Media</option>
																<option value="Membership Organizations">Membership Organizations</option>
																<option value="Metals &amp; Mining">Metals &amp; Mining</option>
																<option value="Pharmaceuticals">Pharmaceuticals</option>
																<option value="Real Estate">Real Estate</option>
																<option value="Retail">Retail</option>
																<option value="Security Products &amp; Services">Security Products &amp; Services</option>
																<option value="Technology">Technology</option>
																<option value="Telecommunications Equipment">Telecommunications Equipment</option>
																<option value="Telecommunications Services">Telecommunications Services</option>
																<option value="Transportation Services">Transportation Services</option>
																<option value="Other">Other</option>
															</select>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<hr className="horizontal-line" />
							<div className="row">
								<div className="grid-layout-col">
									<div className="layout-col col-sm-12 col-xs-12 entry-field form-entry">
										<div id="formElement5" className="elq-field-style form-element-layout row">
											<div className="col-sm-12 col-xs-12 label-style">
												<label className="elq-label ">{props.contactUsForm.formSubject}</label>
											</div>
											<div className="col-sm-12 col-xs-12">
												<div className="row">
													<div className="col-xs-12">
														<div className="field-control-wrapper">
															<input
																type="text"
																className="elq-item-input input-field-style"
																name="Subject"
																onChange={handleChange}
																onBlur={handleBlur}
																id="fe283"
																value={values.Subject}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="grid-layout-col">
									<div className="layout-col col-sm-12 col-xs-12 entry-field form-entry">
										<div id="formElement6" className="elq-field-style form-element-layout row">
											<div className="col-sm-12 col-xs-12 label-style">
												<label className="elq-label ">{props.contactUsForm.formMessage}</label>
											</div>
											<div className="col-sm-12 col-xs-12">
												<div className="row">
													<div className="col-xs-12">
														<div className="field-control-wrapper">
															<textarea
																className="elq-item-textarea message-area input-field-style"
																name="Message"
																value={values.Message}
																autoComplete="off"
																onChange={handleChange}
																onBlur={handleBlur}
																id="fe284"
															></textarea>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="grid-layout-col">
									<div className="layout-col col-sm-12 col-xs-12 entry-field form-entry">
										<div id="formElement7" className="elq-field-style form-element-layout row">
											<div className="col-sm-12 col-xs-12">
												<div className="row">
													<div className="col-xs-12">
														<div className="submit-btn">
															<input
																type="submit"
																className="submit-button-style send "
																disabled={isInputsInvalid == undefined ? true : isInputsInvalid}
																value={props.contactUsForm.formSubmitButton}
																id="fe285"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default Contact;
