import React, { useState, useEffect } from 'react';
import "./Tabs.css";
function Navigation(props) {
    if(props.tabsArray == undefined && props.tabsArray.length <= 0) {
        return null;
    }

    const [activeTab, updateActive] = useState(0);
    const regex = /[^a-zA-Z]/gi;

    function tabOnClickHandler(index, title) {
        updateActive(index);
        let tabObj = props.tabsArray[index];
        props.tabClicked(tabObj);
        let currentUrl = window.location.href.split("#");
        let baseUrl = currentUrl[0];
        window.history.pushState({}, '', baseUrl+`#${title.replace(regex, "").toLowerCase()}`);
    }

   useEffect(() => {
       if(window.location.href.includes("#")) {
            let currentUrl = window.location.href.split("#");
            let currentTab = currentUrl[currentUrl.length - 1];
            let currentTabLowerCase = currentTab.toLowerCase();
            let findTabData = props.tabsArray.filter((obj, index) => {
                if(obj.title.replace(regex, "").toLowerCase() == currentTabLowerCase) {
                    updateActive(index);
                    return obj;
                }
            })
            props.tabClicked(findTabData[0]);
        }
    }, [activeTab]);

    return (
        <div className="pillar-tab">
            {props.tabsArray.map((obj, index) =>(
                obj.title != undefined && obj.title && 
                <div id={`${obj.title.replace(regex, "").toLowerCase()}`} key={index} onClick={()=>tabOnClickHandler(index, obj.title)}>
                    <span  className={index == activeTab ? 'active' : ''}>{obj.title}</span>
                </div>
            ))}
        </div>
    )
}

export default Navigation;
