import { matchRoutes } from 'react-router-config';

const loadInitialData = async (url, routes, options) => {
	const matchedRoutes = matchRoutes(routes, url);

	// TODO: Consider doing an API fetch for routes and validate against a predefined list
	if (matchedRoutes.length <= 0) {
		throw new Error(`No route was found for ${url}.`);
	}

	const { route, match } = matchedRoutes[0];

	if (!route.getInitialData) {
		return {};
	}

	try {
		const initialData = await route.getInitialData({ match, options });

		return initialData;
	} catch (error) {
		throw error;
	}
};

export default loadInitialData;
