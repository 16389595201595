import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid, Row, Cell } from '../Grid';
import Img from '../Img';
import './HeroImage.scss';
import ReactModal from 'react-modal';
import { FaWindowClose } from 'react-icons/fa';

const HeroImage = ({ data }) => {
	const [openModal, setOpenModal] = useState(false);
	const openModaleHandler = () => {
		if (data.addModal) {
			setOpenModal(true);
		}
	};
	const closeModaleHandler = () => {
		setOpenModal(false);
	};
	if (openModal) {
		document.body.style.overflow = 'hidden';
	} else {
		document.body.style.overflow = 'auto';
	}

	return (
		<Grid className="c-editorial-image has-editorial-spacing" noVerticalPadding>
			<Row>
				<Cell columns={2} />
				<Cell columns={8}>
					<div className="c-editorial-image-container">
						<figure className="c-editorial-image-figure">
							<figcaption className="c-editorial-image-caption">{data.title}</figcaption>
							<Img
								src={_.get(data, 'image.file.url', '')}
								imgWidth={_.get(data, 'image.file.details.image.width', '')}
								className="c-editorial-image-image"
								onClick={openModaleHandler}
							/>
						</figure>
						{data.addModal === true && (
							<ReactModal isOpen={openModal} onRequestClose={closeModaleHandler}>
								<figure className="c-editorial-image-container-figure overlay-style">
									<button className="article-modal-button" onClick={closeModaleHandler}>
										<FaWindowClose className="fa-button-style" />
									</button>
									<div className="modal-image-container">
										<img src={_.get(data, 'image.file.url', '')} className="modal-img" />
									</div>
								</figure>
							</ReactModal>
						)}
					</div>
				</Cell>
			</Row>
		</Grid>
	);
};

HeroImage.propTypes = {
	data: PropTypes.object.isRequired
};

export { HeroImage };

export default HeroImage;
