import React from 'react';
import './styles.css';
import Img from '../../Img';

function Card(props) {
  return (
    <>
      <div className='card-wrapper'>
        <h3 className='card-title'>{props.heading!=undefined && props.heading}</h3>
        <div className='img-wrapper'>
          {props.url!=undefined && (
           props.addModal ? <Img className="card-modal-img" src={props.url}/> : <img src={props.url}/>)}
        </div>
        <div className='card-descrip'>
          { props.cta!=undefined && <a href={props.ctaUrl} rel="noopener noreferrer">{props.cta !=undefined && props.cta}</a>}
        </div>
      </div>
    </>
  );
}

export default Card;
