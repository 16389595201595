import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PageContext from '../../context/PageContext';
import {fetchPageContent} from '../../utils/fetchPageContent';
import {virtualPageView} from '../../lib/analytics';
import fetchNewsAPI from './api/get-data';
import pageComponentsList from './../../containers/ComponentsList';
import {HubContainer} from '@sailgp/sailgp-shared-components';
import Logger from './../../lib/logger';
const micrositeURL = process.env.MICROSITE_URL || '';
const logger = Logger.get('HubContainer');


export class ContentHubContainer extends PureComponent {
	constructor(props) {
		super(props);
		const pageSize = 12;
		this.state = {
			hubItemsData: _.get(props, 'data.hubContent.items', []),
			page: _.get(props, 'data.hubContent.page', 1),
			perPage: pageSize,
			hasNextPage: _.get(props, 'data.hubContent.hasNextPage', true),
			pageContent: undefined,
			drawerOpen: false,
			drawerContentUrl: undefined
		};

		this.currentFilters = {
			lang: this.props.data.language,
			selectedTypes: this.props.data.selectedTypes || [],
			selectedFilters: this.props.data.selectedFilters.map(o => o.id) || []
		};

		this.handleTypesFilter = this.handleTypesFilter.bind(this);
		this.handleResetFilter = this.handleResetFilter.bind(this);
		this.handleApplyFilter = this.handleApplyFilter.bind(this);
		this.handleLoadMore = this.handleLoadMore.bind(this);
		this.onLoadingFinished = this.onLoadingFinished.bind(this);
		this.handleCloseEditorial = this.handleCloseEditorial.bind(this);
		this.handleCardClick = this.handleCardClick.bind(this);
		this.updatePageContent = this.updatePageContent.bind(this);
		this.clearPageContent = this.clearPageContent.bind(this);
	}

	async componentDidMount() {

		const gridLayout = this.props && this.props.data && this.props.data.gridLayout ? this.props.data.gridLayout : 'Uniform';
		let pageSize = 12;
		switch (gridLayout) {
			case 'Highlighted':
				pageSize = 13;
				break;
			default:
				pageSize = 12;
				break;
		}

		try {
			const data = await fetchNewsAPI({filters: this.getFilters(pageSize)});
			this.setState({
				hubItemsData: data.items,
				page: data.page,
				perPage: 12,
				hasNextPage: data.hasNextPage
			});
		} catch (error) {
			logger.error(error);
		}
		return {};
	}

	handleTypesFilter(filtersIDArr) {
		const pageFilters = [{type: 'perpage', value: `${this.state.perPage}`}];
		this.currentFilters.selectedTypes = filtersIDArr;
		this.applyFilters(pageFilters);
	}

	/**
	 * Reset current filters and reload
	 *
	 * @memberof HubContainer
	 */
	handleResetFilter() {
		const pageFilters = [{type: 'perpage', value: `${this.state.perPage}`}];
		this.currentFilters.selectedTypes = [];
		this.currentFilters.selectedFilters = [];
		this.applyFilters(pageFilters);
	}

	/**
	 * Apply selected filters (team, race)
	 *
	 * @param {Array} filtersIDArr Filter IDs to be applied
	 * @memberof HubContainer
	 */
	handleApplyFilter(filtersIDArr) {
		const pageFilters = [{type: 'perpage', value: `${this.state.perPage}`}];
		this.currentFilters.selectedFilters = filtersIDArr;
		this.applyFilters(pageFilters);
	}

	getFilters(perPage = this.state.perPage) {
		const filtersArray = [];
		const {lang, selectedTypes, selectedFilters} = this.currentFilters;

		filtersArray.push({type: 'lang', value: lang});
		filtersArray.push({type: 'perpage', value: perPage});

		if (selectedTypes && selectedTypes.length > 0) {
			filtersArray.push({type: 'type', value: selectedTypes.join(',')});
		}

		if (selectedFilters && selectedFilters.length > 0) {
			filtersArray.push({type: 'filter', value: selectedFilters.join(',')});
		}

		return filtersArray;
	}

	/**
	 * Handle drawer menu open state
	 *
	 * @memberof HubContainer
	 */
	async applyFilters(filters) {
		try {
			const data = await fetchNewsAPI({filters: [...this.getFilters(), ...filters]});
			// Force rendering with no items, so the cards are remounted and have their reveal animation
			this.setState({
				hubItemsData: [],
				page: 1,
				perPage: 0,
				hasNextPage: false
			});

			this.setState({
				hubItemsData: data.items,
				page: data.page,
				perPage: data.perPage,
				hasNextPage: data.hasNextPage
			});
		} catch (error) {
			logger.error(error);
		}
	}


	/**
	 * Load more items handle (triggerd by clicking on Load More CTA)
	 *
	 * @memberof HubContainer
	 */
	async handleLoadMore() {
		const pageFilters = [{type: 'page', value: `${this.state.page + 1}`}];
		try {
			const data = await fetchNewsAPI({filters: [...this.getFilters(), ...pageFilters]});
			this.setState({
				hubItemsData: this.state.hubItemsData.concat(data.items),
				page: data.page,
				perPage: data.perPage,
				hasNextPage: data.hasNextPage
			});
		} catch (error) {
			logger.error(error);
		}
	}

	async updatePageContent() {
		try {
			this.setState({
				pageContent: await fetchPageContent(this.state.drawerContentUrl)
			});

		} finally {
			if (this.onLoadingFinished) {
				this.onLoadingFinished();
			}
		}
	}

	clearPageContent() {
		this.setState({
			pageContent: undefined
		});
	}

	onLoadingFinished() {
		this.setState({
			loadingContentUrl: undefined
		});
	}

	handleCloseEditorial() {
		window.history.pushState(null, null, this.urlPrevLocation);
		this.setState({drawerOpen: false});
	}

	handleCardClick(link, type, prevLocation) {
		this.urlPrevLocation = prevLocation;
		this.setState({
			drawerOpen: true,
			drawerContentUrl: link,
			drawerContentType: type,
			loadingContentUrl: link
		},
		() => {
			virtualPageView({
				'page': link,
			});
		});
	}

	render() {
		return (
			<PageContext.Consumer>
				{({activeLanguage}) => (
					<HubContainer pageComponentsList={pageComponentsList} activeLanguage={{activeLanguage}}
								  data={this.props.data}
								  containerState={this.state}
								  handleLoadMore={this.handleLoadMore}
								  handleApplyFilter={this.handleApplyFilter}
								  handleResetFilter={this.handleResetFilter}
								  handleTypesFilter={this.handleTypesFilter}
								  onLoadingFinished={this.onLoadingFinished}
								  handleCloseEditorial={this.handleCloseEditorial}
								  handleCardClick={this.handleCardClick}
								  updatePageContent={this.updatePageContent}
								  clearPageContent={this.clearPageContent}
								  currentFilters={this.currentFilters}
								  micrositeURL={micrositeURL}

					/>
				)}
			</PageContext.Consumer>
		)
	}
}

ContentHubContainer.propTypes = {
	data: PropTypes.object
};
