/**
 * Removes trailing forward slashes if they exist.
 * If the string doesn't end with a slash, we simply return it.
 * @param {String} str - String used to un slash it
 * @returns String
 */
const unTrailingSlashIt = str => {
	if (str.endsWith('/') || str.endsWith('\\')) {
		return unTrailingSlashIt(str.slice(0, -1));
	}

	return str;
};

/**
 * Appends a trailing slash.
 *
 * Will remove a trailing forward slash if it exists already, before adding a
 * trailing forward slash. This prevents double slashing a string or path.
 */
const trailingSlashIt = str => {
	return unTrailingSlashIt(str) + '/';
};

export default trailingSlashIt;
export {
	trailingSlashIt,
	unTrailingSlashIt
};
