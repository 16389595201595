import React, { useState, useEffect, Fragment } from 'react';
import './style.scss';
import HamburgerMenu from '../HamburgerMenu';
import Navigation from '../Navigation';

const StickyHeader = ({ data }) => {
	const siteSettings = data.siteSettings;
	const primaryNavigation = siteSettings.primaryNavigation;
	const logo = siteSettings.logo;
	const logoUrl = logo && logo.file && logo.file.url ? logo.file.url : null;
	const [prevScrollY, setPrevScrollY] = useState(0);
	const [isHeaderHidden, setIsHeaderHidden] = useState(false);
	const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);
	const [navBarOpen, setNavBarOpen] = useState(false);

	const onClickDisplayMenu = (e) => {
		const id = e.target.id;
		const val = id == undefined ? true : false;
		setNavBarOpen(val);
	};

	const createFullNavigation = (navItems) => {
		const fullNavigation = [];
		navItems.forEach((navItem) => {
			const { heading, headingLink, links } = navItem;

			if (links.length > 0) {
				links.forEach(linkItem => {
					const { title, url } = linkItem;
					const fullNavItem = {
						'heading': title,
						'headingLink': url,
						'links': [],
						'isSubLink': true

					}
					fullNavigation.push(fullNavItem);
				});

			}else {
				const fullNavItem = {
					'heading': heading,
					'headingLink': headingLink,
					'links': [],
					'isSubLink': false
				}
				fullNavigation.push(fullNavItem);
			}

		});
		return fullNavigation;
	};

	useEffect(() => {
		function onWindowScroll() {
			const currentScrollY = window.scrollY;

			if (currentScrollY > 100 && currentScrollY > prevScrollY) {
				setIsHeaderHidden(true);
			} else {
				setIsHeaderHidden(false);
				setIsHeaderScrolled(currentScrollY > 800);
			}

			setPrevScrollY(currentScrollY);
		}

		window.addEventListener('scroll', onWindowScroll);
		return () => window.removeEventListener('scroll', onWindowScroll);
	}, [prevScrollY]);

	return (
		<Fragment>
			{navBarOpen && <div className="menu-overlay" onClick={(e) => onClickDisplayMenu(e)}></div>}
			<header className={`new-sticky-header ${isHeaderHidden ? 'header-hidden' : ''} ${isHeaderScrolled ? 'header-scrolled' : ''}`}>

				<div className={'sailgp-logo-header'}>
					<a href="/">
						<img className="sailgp-logo-header-image" src={logoUrl} alt="SailGP Logo"/>
					</a>
				</div>
				<HamburgerMenu navBarOpen={navBarOpen} setNavBarOpen={(e) => setNavBarOpen(e)} navItems={createFullNavigation(primaryNavigation)} subMenuOnly={false} />
				<Navigation navBarOpen={navBarOpen} setNavBarOpen={(e) => setNavBarOpen(e)} navItems={primaryNavigation} hamburgerNav={createFullNavigation(primaryNavigation)}  />
			</header>
		</Fragment>
	);
};

StickyHeader.defaultProps = {
	data: {},
};

export default StickyHeader;
