import React, { useState, Component } from 'react';
import PropTypes from 'prop-types';
import ImgMask from './ImgMask';
import { getMediaResource, getResponsiveMarkup } from './../../lib/media';
import './Img.scss';
import Modal from 'react-modal';

const cache = {};
const imgPropTypes = {
	loader: PropTypes.node,
	unloader: PropTypes.node,
	decode: PropTypes.bool,
	src: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	imgAlt: PropTypes.string,
	imgWidth: PropTypes.number,
	imgHeight: PropTypes.number,
	imgType: PropTypes.string,
	container: PropTypes.func,
	loaderContainer: PropTypes.func,
	unloaderContainer: PropTypes.func,
	angledMask: PropTypes.bool,
	angledMaskPosition: PropTypes.oneOf(['top', 'bottom', 'top-bottom']),
	angledMaskDirection: PropTypes.oneOf(['right', 'left']),
	angledMaskFillColor: PropTypes.string
};

class Img extends Component {
	static propTypes = imgPropTypes;

	static defaultProps = {
		loader: false,
		unloader: false,
		decode: false,
		src: [],
		imgAlt: '',
		imgWidth: 1920,
		// By default, just return what gets sent in. Can be used for advanced rendering such as animations
		container: x => x,
		angledMask: false,
		angledMaskPosition: 'top-bottom',
		angledMaskDirection: 'right',
		angledMaskFillColor: 'transparent'
	};

	constructor(props) {
		super(props);

		this.onLoad = this.onLoad.bind(this);
		this.onError = this.onError.bind(this);

		this.loaderContainer = props.loaderContainer || props.container;
		this.unloaderContainer = props.unloaderContainer || props.container;

		this.sourceList = this.srcToArray(this.props.src);

		for (let i = 0; i < this.sourceList.length; i++) {
			// If image has never been in cache start loading directly
			if (!(this.sourceList[i] in cache)) break;

			// If image has been loaded before just load it again
			if (cache[this.sourceList[i]] === true) {
				this.state = { currentIndex: i, isLoading: false, isLoaded: true };

				return;
			}
		}

		this.state =
			this.sourceList.length > 0
				? { currentIndex: 0, isLoading: true, isLoaded: false, modalIsOpen: false }
				: { isLoading: false, isLoaded: false, modalIsOpen: false };
	}

	srcToArray = src => (Array.isArray(src) ? src : [src]).filter(x => x);

	onLoad() {
		cache[this.sourceList[this.state.currentIndex]] = true;

		if (this.HTMLImgEl) {
			this.setState({ isLoaded: true });
		}
	}

	onError() {
		cache[this.sourceList[this.state.currentIndex]] = false;

		if (!this.HTMLImgEl) return false;

		let nextIndex = 0;

		for (nextIndex = this.state.currentIndex + 1; nextIndex < this.sourceList.length; nextIndex++) {
			const src = this.sourceList[nextIndex];

			if (!(src in cache)) {
				this.setState({ currentIndex: nextIndex });

				break;
			}

			if (cache[src] === true) {
				this.setState({
					currentIndex: nextIndex,
					isLoading: false,
					isLoaded: true
				});

				return true;
			}

			if (cache[src] === false) continue;
		}

		if (nextIndex === this.sourceList.length) return this.setState({ isLoading: false });

		this.loadImg();
	}

	loadImg() {
		const { imgWidth, imgHeight } = this.props;
		const imgSize = {};
		if (imgWidth) imgSize.w = imgWidth;
		if (imgHeight) imgSize.h = imgHeight;

		this.HTMLImgEl = new Image();
		this.HTMLImgEl.src = getMediaResource(this.sourceList[this.state.currentIndex], { ...imgSize });

		if (this.props.decode && this.HTMLImgEl.decode) {
			this.HTMLImgEl.decode().then(this.onLoad).catch(this.onError);
		} else {
			this.HTMLImgEl.addEventListener('load', this.onLoad);
		}

		this.HTMLImgEl.addEventListener('error', this.onError);
	}

	openModal = () => {
		this.setState({ modalIsOpen: true });
		document.body.style.overflow = 'hidden';
	};

	closeModal = () => {
		this.setState({ modalIsOpen: false });
		document.body.style.overflow = 'auto';
	};

	unloadImg() {
		delete this.HTMLImgEl.onerror;
		delete this.HTMLImgEl.onload;

		try {
			delete this.HTMLImgEl.src;
		} catch (error) {
			// On Safari in Strict mode this will throw an exception,
			//  - https://github.com/mbrevda/react-image/issues/187
			// We don't need to do anything about it.
		}

		delete this.HTMLImgEl;
	}
	//modal

	componentDidMount() {
		// If we don't have a loader there is no reason to preload the image.
		// If we do a default jpg version will be downloaded even if the browser supports webp.
		// Also the biggest rendition for it will be loaded even if the viewport is smaller.
		// TODO: Perhaps, add server side webp detection and preload the correct image, with SSR in mind.
		if (this.state.isLoading && this.props.loader) this.loadImg();
	}

	componentWillUnmount() {
		if (this.HTMLImgEl) this.unloadImg();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.loaderContainer = nextProps.loaderContainer || nextProps.container;
		this.unloaderContainer = nextProps.unloaderContainer || nextProps.container;

		const src = this.srcToArray(nextProps.src);

		const srcAdded = src.filter(s => this.sourceList.indexOf(s) === -1);
		const srcRemoved = this.sourceList.filter(s => src.indexOf(s) === -1);

		// If src prop changed, restart the loading process
		if (srcAdded.length > 0 || srcRemoved.length > 0) {
			this.sourceList = src;

			if (src.length === 0) return this.setState({ isLoading: false, isLoaded: false });

			this.setState({ currentIndex: 0, isLoading: true, isLoaded: false }, this.loadImg);
		}
	}

	render() {
		const {
			container,
			loader,
			unloader,

			// props to exclude from the rest property
			src,
			imgAlt,
			imgWidth,
			imgHeight,
			imgType,
			decode,
			loaderContainer,
			unloaderContainer,
			angledMask,
			angledMaskPosition,
			angledMaskDirection,
			angledMaskFillColor,

			...rest
		} = this.props;
		const { isLoaded, isLoading } = this.state;
		let shouldRender = false;
		const imgSize = {};
		let imgContentType = 'jpg';

		// If there is no image loader container we should render the IMG straight away during SSR.
		// This will let the browser decide when the network request fires, and progressive JPG,
		// will load properly without waiting for the entire content to load.
		if ((loader && isLoaded) || !loader) {
			shouldRender = true;
		}

		if (shouldRender) {
			const imgSrc = this.sourceList[this.state.currentIndex];
			const imgProps = {
				...rest
			};
			const maskProps = {
				angledMask,
				angledMaskPosition,
				angledMaskDirection,
				angledMaskFillColor
			};

			if (imgWidth) imgSize.w = imgWidth;
			if (imgHeight) imgSize.h = imgHeight;

			if (imgType) {
				imgContentType = imgType.replace('image/', '');
			}

			return container(
				<>
					<ImgMask {...maskProps}>
						<picture onClick={this.openModal} {...imgProps}>
							<source
								sizes={getResponsiveMarkup(imgSrc, 'sizes', { ...imgSize })}
								srcSet={getResponsiveMarkup(imgSrc, 'srcset', { ...imgSize, format: 'webp' })}
								type="image/webp"
							/>
							<img
								sizes={getResponsiveMarkup(imgSrc, 'sizes', { ...imgSize, format: imgContentType })}
								srcSet={getResponsiveMarkup(imgSrc, 'srcset', { ...imgSize, format: imgContentType })}
								src={getMediaResource(imgSrc, { ...imgSize, format: imgContentType })}
								alt={imgAlt}
							/>
						</picture>
					</ImgMask>

					<Modal isOpen={this.state.modalIsOpen} onRequestClose={this.closeModal} ariaHideApp={false}>
						<div className="overlay-style">
							<button onClick={this.closeModal} className="c-img-modal-button">
								X
							</button>
							<ImgMask {...maskProps} onClick={this.openModal}>
								<picture {...imgProps}>
									<source
										sizes={getResponsiveMarkup(imgSrc, 'sizes', { ...imgSize })}
										srcSet={getResponsiveMarkup(imgSrc, 'srcset', { ...imgSize, format: 'webp' })}
										type="image/webp"
									/>
									<img
										className="image-modal-opened"
										onClick={this.closeModal}
										sizes={getResponsiveMarkup(imgSrc, 'sizes', { ...imgSize, format: imgContentType })}
										srcSet={getResponsiveMarkup(imgSrc, 'srcset', { ...imgSize, format: imgContentType })}
										src={getMediaResource(imgSrc, { ...imgSize, format: imgContentType })}
										alt={imgAlt}
									/>
								</picture>
							</ImgMask>
						</div>
					</Modal>
				</>
			);
		}

		// If we are still trying to load, show img and a loader if requested
		if (!isLoaded && isLoading) {
			return loader ? this.loaderContainer(loader) : null;
		}

		// If we have given up on loading, show a place holder if requested, or nothing
		if (!isLoaded && !isLoading) {
			return unloader ? this.unloaderContainer(unloader) : null;
		}

		return null;
	}
}

export default Img;
